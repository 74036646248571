<template>
  <div>
    <b-overlay
      :show="driversLoading"
      variant="white"
    >
      <b-card class="set-cargo-driver-box">
        <b-row>
          <!-- User Info: Left col -->
          <b-col
            cols="12"
            xl="12"
            class="d-flex justify-content-between flex-column"
          >
            <!-- Action Buttons -->
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column w-100">
                <div class="mb-0">
                  <h4 class="mb-0">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-50"
                      size="21"
                    />
                    Autista
                  </h4>
                  <div class="mt-1">
                    <b-form-group>
                      <v-select
                        v-model="driver"
                        :reduce="driver => driver.ds_trasportatore + ' ' + (driver.telefono_trasportatore || '')"
                        :options="drivers"
                        :loading="driversLoading"
                        label="ds_trasportatore"
                      >
                        <template #option="{ ds_trasportatore, telefono_trasportatore }">
                          {{ ds_trasportatore + ' ' + ( telefono_trasportatore || '' ) }}
                        </template>

                        <template #selected-option="{ ds_trasportatore, telefono_trasportatore }">
                          {{ ds_trasportatore + ' ' + ( telefono_trasportatore || '' ) }}
                        </template>

                      </v-select>
                    </b-form-group>
                    <div>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="btn-icon mr-50 mb-50"
                        @click.prevent="saveDriver"
                      >
                        <feather-icon
                          icon="CheckIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">ASSOCIA</span>
                      </b-button>
                    </div>
                    <div class="mt-1">
                      <b-badge
                        v-if="driverSaved"
                        variant="light-success"
                        class="d-block"
                      >
                        Autista configurato
                      </b-badge>
                      <b-badge
                        v-else
                        variant="light-warning"
                        class="d-block"
                      >
                        Autista non configurato
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BOverlay,
  VBTooltip,
  BButton,
  BBadge,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BOverlay,
    BButton,
    BBadge,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    cargo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      drivers: [],
      driver: this.cargo.trasportatore || null,
      driverSaved:  Boolean(this.cargo.trasportatore),
      driversLoading: false,
    }
  },
  created() {
    this.loadDriver()
  },
  methods: {
    loadDriver() {
      this.driversLoading = true
      this.$http.get('/driver/list').then(res => {
        this.drivers = res.data.data.drivers
        this.driversLoading = false
      })
        .catch(error => {
          this.driversLoading = false
        })
    },
    saveDriver() {
      this.driversLoading = true

      if (this.cargo && this.cargo.c_carico && this.driver !== undefined) {
        const obj = {
          c_carico: this.cargo.c_carico,
          trasportatore: this.driver,
        }

        this.$http.post('/cargo/driver/set', obj).then(res => {
          const driver = obj.trasportatore

          if (driver) {
            this.driverSaved = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Autista salvato',
                icon: 'PhoneIcon',
                variant: 'success',
                text: `${driver} impostato come autista per questo carico!`,
              },
            })
          } else {
            this.driverSaved = false
          }

          this.driversLoading = false
          this.$emit('driverSet', { driver })

        })
          .catch(error => {
            this.driversLoading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ops! Errore',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Qualcosa è andato storto. Ricarica la pagina e riprova. Grazie.',
              },
            })
          })
      }
    },
  },
}
</script>
