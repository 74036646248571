<template xmlns="http://www.w3.org/1999/html">
  <div class="page-edit-cargo">
    <div class="breadcrumb-wrapper">
      <div v-if="cargo">
        <h2 class="content-header-title float-left pr-1 mb-0">
          Conf. Carico {{ cargo.c_carico }}
        </h2>
        <b-breadcrumb
          class="mb-1"
        >
          <b-breadcrumb-item to="/">
            <feather-icon
              icon="HomeIcon"
              size="16"
              class="align-text-top"
            />
          </b-breadcrumb-item>
          <b-breadcrumb-item
            to="/configurazione-carichi"
            class="d-md-inline-block d-sm-none d-none"
          >
            <span>Conf. Carichi</span>
          </b-breadcrumb-item>
          <b-breadcrumb-item active class="d-md-inline-block d-sm-none d-none">
            <span>Conf. Carico {{ cargo.c_carico }}</span>
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <b-row>
      <b-col
        cols="12"
        xl="4"
        lg="4"
        md="12"
      >
        <b-overlay
          :show="showOverlay"
          variant="white"
        >
          <b-card class="cargo-info-box">

            <b-row v-if="cargo && parseInt(cargo.fg_stato_piano) <= 9">
              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="12"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <div class="d-flex flex-column w-100">
                    <div class="mb-1">
                      <h1 class="mb-0">
                        CARICO {{ cargo.c_carico }}
                      </h1>

                      <div><span class="text-uppercase font-weight-bold">{{ cargo.ds_piano_carico }}</span></div>
                      <div><span class="text-important text-uppercase font-weight-bold">{{ cargo.ds_vettore1 }}</span></div>
                    </div>
                    <table class="mt-2 mt-xl-0 w-100">
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Data partenza</span>
                        </th>
                        <td class="pb-50">
                          {{ new Intl.DateTimeFormat('en-GB').format(Date.parse(cargo.dt_partenza)) }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="MapIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">KM Stimati</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ cargo.n_km_stimati }} Km
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="MapPinIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Numero Consegne</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                          {{ cargo.n_consegne }}
                        </td>
                      </tr>
                      <tr>
                        <th class="pb-50">
                          <feather-icon
                            icon="PackageIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Numero colli</span>
                        </th>
                        <td class="pb-50">
                          {{ cargo.n_colli }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <feather-icon
                            icon="BoxIcon"
                            class="mr-75"
                          />
                          <span class="font-weight-bold">Volume</span>
                        </th>
                        <td>
                          {{ cargo.n_volume }} m<sup>3</sup>
                        </td>
                      </tr>
                    </table>
                    <div v-if="cargo.nota_consegna" class="mt-1">
                      <strong>NOTA CARICO:</strong> {{ cargo.nota_consegna }}
                    </div>
                    <div class="mt-1">
                      <b-badge
                        :variant="statusVariant(cargo.ds_stato)"
                        class="d-block"
                      >
                        Sequenza: {{ cargo.ds_stato }}
                      </b-badge>
                    </div>
                    <div class="mt-2">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        :to="{ name: 'configurazione-carichi'}"
                      >
                        INDIETRO
                      </b-button>
                    </div>
                  </div>
                </div>

              </b-col>
            </b-row>

          </b-card>
        </b-overlay>

        <!-- Imposta automezzo -->
        <div v-if="cargo && parseInt(cargo.fg_stato_piano) <= 9">
          <slot
            name="cargo-vehicle"
            :cargo="cargo"
          />
        </div>
        <!-- END | Imposta automezzo -->

        <!-- Imposta autista -->
        <div v-if="cargo && parseInt(cargo.fg_stato_piano) <= 9">
          <slot
            name="cargo-driver"
            :cargo="cargo"
          />
        </div>
        <!-- END | Imposta autista -->

        <!-- Imposta utente autista -->
        <div v-if="cargo && parseInt(cargo.fg_stato_piano) <= 9">
          <slot
            name="cargo-user-driver"
            :cargo="cargo"
          />
        </div>
        <!-- END | Imposta utente autista -->

        <!-- Imposta data di consegna -->
        <div v-if="cargo && parseInt(cargo.fg_stato_piano) <= 9">
          <b-overlay
            :show="showOverlayDeliveryDate"
            variant="white"
          >
            <b-card class="set-cargo-date-box">
              <b-row>
                <b-col
                  cols="12"
                  xl="12"
                  class="d-flex justify-content-between flex-column"
                >
                  <div class="d-flex justify-content-start">
                    <div class="d-flex flex-column w-100">
                      <div class="mb-0">
                        <h4 class="mb-0">
                          <feather-icon
                            icon="CalendarIcon"
                            class="mr-50"
                            size="21"
                          />
                          Data di consegna
                        </h4>
                        <div class="mt-1">
                          <div>
                            <p><small>Imposta la data di consegna prevista per le consegne selezionate.</small></p>
                            <p><small>Il cliente potrà visualizzare la data impostata.</small></p>
                          </div>
                          <div class="mt-1">
                            <b-form-checkbox
                              v-model="allDeliveriesSelected"
                              :indeterminate="allDeliveriesIndeterminate"
                              @change="toggleSelectAllDeliveries"
                            >
                              {{ allDeliveriesSelected ? 'Deseleziona tutto' : 'Seleziona tutto' }}
                            </b-form-checkbox>
                          </div>
                          <div class="mt-1">
                            <div class="d-flex justify-content-center">
                              <b-calendar
                                v-if="notValidDates !== null"
                                v-model="deliveriesDate"
                                :disabled="!selectedDeliveries.length"
                                class="w-100"
                                :locale="locale"
                                v-bind="calendarLabels[locale] || {}"
                                :min="new Date()"
                                :date-disabled-fn="dateDisabled"
                                :start-weekday="CalendarStartWeekday"
                                block
                                selected-variant="success"
                                today-variant="info"
                              />
                            </div>
                          </div>
                          <div class="mt-1">
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="success"
                              class="btn-icon mr-50"
                              :disabled="!selectedDeliveries.length || !deliveriesDate || !cargo.c_carico"
                              @click.prevent="saveDeliveryDates"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                class="mr-50"
                              />
                              <span class="align-middle">SALVA</span>
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="outline-secondary"
                              class="mr-50"
                              :disabled="!selectedDeliveries.length || !deliveriesDate || !cargo.c_carico"
                              @click="clearDeliveriesDate"
                            >
                              <span class="align-middle">RESET</span>
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-overlay>
        </div>
        <!-- END | Imposta data di consegna -->
      </b-col>
      <b-col
        cols="12"
        xl="8"
        lg="8"
        md="12"
      >
        <b-overlay
          :show="showOverlayList"
          variant="white"
        >
          <b-card class="cargo-info-box">
            <b-row v-if="cargo && deliveries && parseInt(cargo.fg_stato_piano) <= 9">
              <b-col
                cols="12"
                xl="12"
                class="d-flex justify-content-between flex-column"
              >
                <div class="pb-2">
                  <div class="pb-2">
                    <h2 class="pb-1">
                      Sequenza di scarico
                    </h2>
                    <b-alert
                      v-if="cargo && parseInt(cargo.fg_stato_consegna) >= 50 && parseInt(cargo.fg_stato_consegna) < 80"
                      variant="danger"
                      class="mb-2"
                      show
                    >
                      <div class="alert-body">
                        <feather-icon
                          icon="AlertTriangleIcon"
                          class="mr-50"
                          size="21"
                        />
                        Sequenza di scarico configurata in attesa di approvazione.
                      </div>
                    </b-alert>
                    <p v-if="canOrderSequence">
                      <feather-icon
                        class="text-body"
                        icon="InfoIcon"
                        size="21"
                      />
                      <small>Trascina le consegne per configurare la sequenza di scarico. Quando hai finito clicca il
                        pulsante
                        <strong>"CONFERMA"</strong>.</small>
                    </p>
                    <p>
                      <feather-icon
                        class="text-body"
                        icon="InfoIcon"
                        size="21"
                      />
                      <small>
                        Quando il badge degli ordini è di questo colore
                        <span class="mr-50">
                          <feather-icon
                            badge="+1"
                            badge-classes="bg-warning"
                            class="text-body"
                            icon="ShoppingCartIcon"
                          />
                        </span>
                        significa che sono stati <strong>aggiunti degli ordini alla consegna</strong>.
                        Puoi cliccare sull'icona per visualizzare gli ordini nel dettaglio.
                      </small>
                    </p>
                  </div>
                  <div class="d-flex justify-content-start">
                    <!--
                    <b-button
                      v-if="canOrderSequence"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mr-1"
                      @click="confirmCargoDeliverySequence"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">CONFERMA LA SEQUENZA</span>
                    </b-button>
                    -->

                    <b-button
                      v-if="canOrderSequence"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mr-1"
                      v-b-modal.modal-confirm-sequence
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">CONFERMA LA SEQUENZA</span>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      :to="{ name: 'configurazione-carichi'}"
                    >
                      INDIETRO
                    </b-button>
                  </div>
                </div>

                <!-- Elenco consegne -->
                <b-form-checkbox-group
                  id="selected-deliveries"
                  v-model="selectedDeliveries"
                  name="selected-deliveries"
                >
                  <b-list-group v-if="cargo">
                    <!-- draggable -->
                    <draggable
                      v-model="deliveries"
                      class="list-group list-group-flush"

                      filter=".no-drag"
                      :disabled="!canOrderSequence"
                      @end="onEnd"
                    >
                      <transition-group
                        type="transition"
                        name="flip-list"
                      >
                        <b-list-group-item
                          v-for="listItem in deliveries"
                          :key="listItem.n_sequenza"
                          class="w-100 delivery"
                          tag="li"
                        >
                          <b-row class="no-gutters">
                            <b-col
                              cols="2"
                              xl="1"
                              class="d-flex align-items-center justify-content-center p-75 checkbox-box no-drag"
                            >
                              <div class="no-gutters d-flex flex-wrap  align-items-center justify-content-center w-100">
                                <div
                                  class="align-items-center justify-content-center flex-column"
                                >
                                  <div class="p-0 delivery-checkbox-wrapper">
                                    <b-form-checkbox
                                      class="mr-0"
                                      :value="listItem.n_consegna"
                                    />
                                  </div>
                                </div>
                              </div>
                            </b-col>
                            <b-col
                              cols="10"
                              :xl="canOrderSequence ? '5' : '6'"
                              class="d-flex align-content-center justify-content-left flex-wrap py-1 delivery-info-box no-drag"
                            >
                              <div class="primary w-100">
                                <span class="delivery-ds">
                                  <b-badge
                                    variant="important"
                                    pill
                                    class="badge-round  align-self-center mr-25"
                                  >
                                    {{ listItem.n_sequenza }}
                                  </b-badge>
                                  {{ `[${listItem.c_nome_consegna}] ${listItem.ds_destinazione}` }}
                                </span>
                              </div>
                              <div
                                v-if="listItem.dt_consegna_prevista_proposta"
                                class="date mt-25 w-100 text-important"
                              >
                                <span
                                  v-b-tooltip.hover.v-dark
                                  title="Data di consegna prevista"
                                  class="text-important"
                                >
                                  <feather-icon
                                    variant="primary"
                                    icon="CalendarIcon"
                                  />
                                  <small>
                                    {{
                                      new
                                      Intl.DateTimeFormat('en-GB').format(Date.parse(listItem.dt_consegna_prevista_proposta))
                                    }}
                                  </small>
                                </span>
                              </div>
                              <div class="address mt-25 w-100">

                                {{ listItem.indirizzo_consegna }}<br>
                                <span class="text-important"><strong>{{listItem.citta_consegna}}</strong></span>
                                {{listItem.n_cap_consegna}}
                                <span class="text-danger"><strong>({{listItem.c_provincia_consegna}})</strong></span>

                              </div>
                            </b-col>
                            <b-col
                              cols="12"
                              :xl="canOrderSequence ? '5' : '5'"
                              class="d-flex align-items-center justify-content-lg-end justify-content-center flex-wrap px-1 pb-xl-0 pb-1 no-drag icons-box"
                            >
                              <div
                                v-b-tooltip.hover.v-dark
                                title="Numero colli"
                                class="p-50"
                              >
                                <strong>{{ parseFloat(listItem.n_colli) }}</strong>
                                <feather-icon
                                  class="text-body"
                                  icon="PackageIcon"
                                  size="21"
                                />
                              </div>

                              <div
                                v-b-tooltip.hover.v-dark
                                title="Volume"
                                class="p-50 pr-1"
                              >
                                <strong>{{ parseFloat(listItem.n_volume) + 'm³' }}</strong>
                                <feather-icon
                                  class="text-body"
                                  icon="BoxIcon"
                                  size="21"
                                />
                              </div>
                              <div
                                v-b-tooltip.hover.v-dark
                                title="Apri mappa"
                                class="p-50"
                              >
                                <a
                                  :href="'https://www.google.com/maps/dir/?api=1&destination='+listItem.n_latitudine + ',' + listItem.n_longitudine"
                                  target="_blank"
                                >
                                  <feather-icon
                                    class="text-body"
                                    icon="MapPinIcon"
                                    size="21"
                                  />
                                </a>
                              </div>
                              <div
                                v-b-tooltip.hover.v-dark
                                v-b-modal.modal-contacts
                                title="Contatti utili"
                                class="p-50"
                                @click="getDeliveryData(listItem.n_consegna)"
                              >
                                <feather-icon
                                  class="text-body"
                                  icon="PhoneIcon"
                                  size="21"
                                />
                              </div>
                              <div
                                v-b-tooltip.hover.v-dark
                                v-b-modal.modal-orders
                                title="Visualizza ordini"
                                class="p-50 pr-1"
                                @click="getCargoDeliveryOrders(listItem.n_consegna)"
                              >
                                <feather-icon
                                  :badge="parseInt(listItem.fg_minicommessa) ? ((parseInt(listItem.n_ordini-listItem.fg_minicommessa) ? listItem.n_ordini-listItem.fg_minicommessa : '') + '+' + listItem.fg_minicommessa) : listItem.n_ordini"
                                  :badge-classes="parseInt(listItem.fg_minicommessa) ? 'bg-warning' : 'bg-success'"
                                  class="text-body"
                                  icon="ShoppingCartIcon"
                                  size="21"
                                />
                              </div>

                            </b-col>
                            <b-col
                              cols="12"
                              xl="1"
                              class="align-items-center justify-content-left ml-auto drag-wrapper flex-wrap"
                              :class="{ 'cursor-move d-flex' : canOrderSequence, 'hidden' : !canOrderSequence }"
                            >
                              <div
                                class="d-flex align-items-center justify-content-center bg-secondary bg-lighten-2 text-white drag w-100 h-100 py-1"
                              >
                                <feather-icon
                                  icon="MoveIcon"
                                  class=""
                                />
                              </div>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="listItem.notes && listItem.notes.length"
                            class="no-gutters notes-row no-drag"
                          >
                            <b-col
                              cols="12"
                            >
                              <app-collapse>
                                <app-collapse-item title="Note">
                                  <ul>
                                    <li
                                      v-for="(note, index) in listItem.notes"
                                      :key="index"
                                    >
                                      {{ note }}
                                    </li>
                                  </ul>
                                </app-collapse-item>
                              </app-collapse>
                            </b-col>
                          </b-row>
                        </b-list-group-item>
                      </transition-group>
                    </draggable>
                  </b-list-group>
                </b-form-checkbox-group>
                <!-- END | Elenco consegne -->
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <!-- Modale ordini consegna -->
    <b-modal
      id="modal-orders"
      title="Ordini"
      centered
      size="lg"
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-overlay
        :show="showOverlayOrders"
        variant="white"
      >
        <b-card-text class="modal-content-wrapper">
          <div v-if="cargo_delivery_orders">
            <div class="p-50">
              <p>In <span class="highlighted">GIALLO</span> sono evidenziati gli ordini aggiunti.</p>
            </div>
            <table class="mt-2 mt-xl-0 w-100 delivery-orders-table">
              <tr>
                <th class="p-50">
                  <span class="font-weight-bold">ORDINE</span>
                </th>
                <th class="p-50">
                  <span class="font-weight-bold">RIFERIMENTO</span>
                </th>
                <th class="p-50">
                  <span class="font-weight-bold">RIF. SCARICO</span>
                </th>
              </tr>
              <tr
                v-for="order in cargo_delivery_orders"
                :key="order.n_ordine"
                :class="{ 'agg' : parseInt(order.fg_minicommessa) }"
              >
                <td class="p-50">
                  {{ `${order.n_anno}-${order.c_numeratore}-${order.n_ordine}` }}
                </td>
                <td class="p-50">
                  {{ `${order.riferimento_cliente}` }}
                </td>
                <td class="p-50">
                  {{order.rif_scarico_oct}}
                </td>
              </tr>
            </table>
          </div>
        </b-card-text>
      </b-overlay>
    </b-modal>

    <!-- Modale contatti utili -->
    <b-modal
      id="modal-contacts"
      title="Contatti utili"
      centered
      ok-only
      ok-variant="outline-secondary"
      ok-title="Chiudi"
    >
      <b-card-text
        v-if="cargo_delivery_data"
      >

        <div class="mt-1">
          <div
            v-if="cargo_delivery_data.telefono_capo_area"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CAPO AREA
            </h4>
            <strong>{{ `${cargo_delivery_data.ds_capo_area}` }}</strong><br>
            {{ `Tel.: 041 5899${cargo_delivery_data.telefono_capo_area}` }}
          </div>
          <div
            v-if="cargo_delivery_data.ds_corrispondente_reclami"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              REFERENTE RECLAMI
            </h4>
            <strong>{{ `${cargo_delivery_data.ds_corrispondente_reclami}` }}</strong><br>
            {{ `Tel.: 041 5899${cargo_delivery_data.tel_corrispondente_reclami}` }}<br>
            {{ `E-mail: ${cargo_delivery_data.email_corrispondente_reclami}` }}
          </div>
          <div
            v-if="cargo_delivery_data.telefono_agente"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              AGENTE
            </h4>
            {{ `${cargo_delivery_data.ds_agente}` }}<br>
            {{ `Tel.: ${cargo_delivery_data.telefono_agente}` }}
          </div>

          <div
            v-if="cargo_delivery_data.client_contacts.length"
            class="mb-1 pb-1 border-bottom"
          >
            <h4 class="mb-50">
              CONTATTI DEL CLIENTE
            </h4>
            <div
              v-for="contact in cargo_delivery_data.client_contacts"
              :key="contact.PK"
              class="mb-50"
            >
              <div v-if="contact.n_telefono">
                <span v-if="contact.contatto"><strong>{{ `${contact.contatto}` }}</strong><br></span>
                Tel.: {{ contact.n_telefono }}
                <span v-if="contact.ds_raggr_tipi_doc">{{ `[${contact.ds_raggr_tipi_doc}]` }}</span>
              </div>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>

    <!-- Modale conferma sequenza -->
    <b-modal
      id="modal-confirm-sequence"
      centered
      ref="confirm-modal"
      title="Sei sicuro?"
      ok-title="CONFERMA"
      cancel-title="ANNULLA"
      cancel-variant="outline-secondary"
      @show="resetConfirmModal"
      @hidden="resetConfirmModal"
      @ok="handleConfirmOk"
    >

      <b-overlay
        :show="showOverlayConfirmModal"
        variant="white"
      >
        <p>Confermando la sequenza i dati sarannno inviati alla sede e non potrai più modificarli.</p>
        <form
          ref="form-confirm"
          @submit.stop.prevent="handleConfirmSubmit"
        >
          <b-form-group
            label="Nota (facoltativa)"
            label-for="note-input"
            invalid-feedback="Name is required"
          >
            <b-form-input
              id="note-input"
              v-model="confirm_note"
            />
          </b-form-group>
        </form>
      </b-overlay>
    </b-modal>
  </div>

</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BPagination,
  BFormGroup,
  BAlert,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardText,
  BOverlay,
  BListGroupItem,
  BListGroup,
  VBTooltip,
  BModal,
  VBModal,
  BButton,
  BSidebar,
  BFormCheckbox,
  BFormCheckboxGroup,
  VBToggle,
  BCalendar,
  BBreadcrumb,
  BBreadcrumbItem,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    draggable,
    BRow,
    BCol,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardText,
    BOverlay,
    BListGroupItem,
    BListGroup,
    BAlert,
    VBTooltip,
    BModal,
    VBModal,
    BButton,
    VBToggle,
    BSidebar,
    AppCollapse,
    AppCollapseItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCalendar,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      locale: 'it',
      dir: false,
      emptyTableStatus: 'loading',
      showOverlay: false,
      showOverlayList: false,
      showOverlayOrders: false,
      showOverlayDeliveryDate: false,
      showOverlayConfirmModal: false,
      cargo: null,
      deliveries: null,
      confirm_note: '',
      cargo_delivery_orders: null,
      cargo_delivery_data: null,
      searchTerm: '',
      selectedDeliveries: [],
      allDeliveriesSelected: false,
      allDeliveriesIndeterminate: false,
      deliveriesDate: '',
      notValidDates: null,
      CalendarStartWeekday: 1,
      calendarLabels: {
        it: {
          labelToday: 'Oggi',
          labelNoDateSelected: 'Nessuna data selezionata',
          labelCalendar: 'Calendario',
          labelHelp: 'Seleziona almeno una consegna',
        },
      },
    }
  },
  computed: {
    deliveriesID() {
      if (this.deliveries) {
        return this.deliveries.map(({ n_consegna }) => n_consegna)
      }
    },
    canOrderSequence() {
      if (this.cargo && this.cargo.fg_stato_consegna) {
        return parseInt(this.cargo.fg_stato_consegna) < 50
      }
      return false
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Da configurare': 'light-dark',
        'In fase di configurazione': 'light-warning',
        'Configurazione conclusa': 'light-info',
        'Configurazione conclusa con avvisi': 'light-danger',
        'Configurazione confermata': 'light-success',
        'Configurazione rifiutata': 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    selectedDeliveries(newValue, oldValue) {
      if (newValue.length === 0) {
        this.allDeliveriesIndeterminate = false
        this.allDeliveriesSelected = false
      } else if (newValue.length === this.deliveriesID.length) {
        this.allDeliveriesIndeterminate = false
        this.allDeliveriesSelected = true
      } else {
        this.allDeliveriesIndeterminate = true
        this.allDeliveriesSelected = false
      }
    },
  },
  created() {
    this.showOverlay = true

    this.$http.get(`/cargo/info/${this.$route.params.cargo}`).then(res => {
      this.cargo = res.data.data.cargo
      this.showOverlay = false
    }).catch(() => {
      this.showOverlay = false
    })

    this.$http.get('/calendar/shipment/not-valid-dates').then(res => {
      this.notValidDates = res.data.data.not_valid_dates
    })

    this.loadDeliveries(true)
  },
  methods: {
    loadDeliveries(removeOverlay) {
      this.showOverlayList = true
      return this.$http.get(`/cargo/deliveries/${this.$route.params.cargo}`).then(res => {
        this.deliveries = res.data.data.deliveries
        if (removeOverlay) {
          this.showOverlayList = false
        }
      }).catch(error => {
        this.showOverlayList = false
      })
    },
    // Modifico la sequenza di scarico dopo aver spostato la riga
    onEnd(change) {
      const moveData = {
        // eslint-disable-next-line no-underscore-dangle
        c_carico: change.item._underlying_vm_.c_carico,
        // eslint-disable-next-line no-underscore-dangle
        n_consegna: change.item._underlying_vm_.n_consegna,
        frontend_position: change.newIndex + 1,
      }

      this.showOverlayList = true
      this.$http.post('/cargo/delivery-move', moveData).then(res => {
        this.showOverlayList = false
        if (res.data.success) {
          this.deliveries = res.data.data.deliveries

          if (parseInt(this.cargo.fg_stato_consegna) === 35) {
            this.setCargoStatus(40)
          }
        }
      }).catch(() => {
        this.showOverlayList = false
      })
    },
    // recupero gli ordini della consegna selezionata
    getCargoDeliveryOrders(delivery) {
      this.cargo_delivery_orders = null
      this.showOverlayOrders = true

      this.$http.get(`/cargo/delivery/${this.$route.params.cargo}/${delivery}/orders`).then(res => {
        this.cargo_delivery_orders = res.data.data.orders
        this.showOverlayOrders = false
      }).catch(error => {
        this.showOverlayOrders = false
      })
    },
    // recupero i dati della consegna selezionata
    getDeliveryData(delivery) {
      this.cargo_delivery_data = null
      this.cargo_delivery_data = this.deliveries.filter(el => el.n_consegna === delivery)[0]
    },
    // Confermo la sequenza di scarico
    confirmCargoDeliverySequence() {
      this.$bvModal
        .msgBoxConfirm('Confermando la sequenza i dati sarannno inviati alla sede e non potrai più modificarli.', {
          title: 'Sei sicuro?',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'CONFERMA',
          cancelTitle: 'ANNULLA',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.showOverlayList = true
            this.setCargoStatus(50).then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Sequenza i carico confermata',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'I dati sono stati inviati alla sede per revisione e approvazione.',
                },
              })
              this.showOverlayList = false
            }).catch(() => {
              this.showOverlayList = false
            })
          }
        })
    },
    resetConfirmModal() {
      this.confirm_note = ''
    },
    handleConfirmOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleConfirmSubmit()
    },
    handleConfirmSubmit() {

      this.$nextTick(() => {
        this.$refs['confirm-modal'].toggle('#toggle-btn')
      })

      this.showOverlayList = true
      this.showOverlayConfirmModal = true
      this.setCargoStatus(50).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Sequenza di carico confermata',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'I dati sono stati inviati alla sede per revisione e approvazione.',
          },
        })
        this.showOverlayList = false
        this.showOverlayConfirmModal = false
      }).catch(() => {
        this.showOverlayList = false
        this.showOverlayConfirmModal = false
      })
    },
    toggleSelectAllDeliveries(checked) {
      this.selectedDeliveries = checked ? this.deliveriesID.slice() : []
    },
    setCargoStatus(status) {
      const obj = {
        c_carico: this.$route.params.cargo,
        c_stato: status,
        nota: this.confirm_note ?? NULL
      }

      // eslint-disable-next-line no-shadow
      return this.$http.post('/cargo/status/set', obj).then(res => {
        this.cargo.fg_stato_consegna = res.data.data.cargo.fg_stato_consegna
        this.cargo.ds_stato = res.data.data.cargo.ds_stato
        this.cargo.nota_consegna = res.data.data.cargo.nota_consegna
      })
    },
    saveDeliveryDates() {
      this.showOverlayList = true
      this.showOverlayDeliveryDate = true

      if (this.cargo && this.cargo.c_carico && this.deliveriesDate) {
        const obj = {
          c_carico: this.cargo.c_carico,
          dt_consegna_prevista_proposta: this.deliveriesDate,
        }

        if (this.allDeliveriesSelected) {
          obj.n_consegna = -1
        } else {
          obj.n_consegna = this.selectedDeliveries
        }

        this.$http.post('/cargo/delivery/date/set', obj).then(res => {
          this.loadDeliveries(false).then(() => {
            if (parseInt(this.cargo.fg_stato_consegna) === 35) {
              this.setCargoStatus(40)
            }

            this.selectedDeliveries = []
            this.allDeliveriesSelected = false
            this.showOverlayList = false
            this.showOverlayDeliveryDate = false
            this.deliveriesDate = ''
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Data di consegna',
                icon: 'CalendarIcon',
                variant: 'success',
                text: 'Data di consegna impostata per i carichi selezionati.',
              },
            })
          }).catch(error => {
            this.showOverlayList = false
            this.showOverlayDeliveryDate = false
          })
        })
          .catch(error => {
            this.showOverlayList = false
            this.showOverlayDeliveryDate = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ops! Errore',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Qualcosa è andato storto. Ricarica la pagina e riprova. Grazie.',
              },
            })
          })
      }
    },
    clearDeliveriesDate() {
      this.deliveriesDate = ''
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      //console.log(ymd);
      //const weekday = date.getDay()
      //const day = date.getDate()
      // Return `true` if the date should be disabled
      return this.notValidDates.includes(ymd)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/core.scss";
@import "~/src/assets/scss/variables/_variables.scss";

.delivery-orders-table{
  tr {
    &.agg {
      background: $highlighted-yellow !important;
    }
  }
}

.page-edit-cargo {
  .cargo-info-box {
    min-height: 260px;
  }

  .list-group-item {
    /* transition: all 0; */
    padding: 0 !important;

    &.delivery {

      &:hover{
        background-color: #ffffff !important;
      }

      .delivery-ds{
        font-size: 17px;
        font-weight: bold;
      }

      .address {
        margin-top: 6px;
        line-height: 1.1 !important;
      }
    }

    .delivery-checkbox-wrapper {
      line-height: 1 !important;
      margin-top: 3px !important;
    }

    .drag-wrapper {

      .drag {

      }
    }
  }

  .notes-row {

    .collapse-default {
      .card {
        .card-header {
          border-radius: 0;
          background: $highlighted-yellow !important;
          color: $dark;
          padding: 0.5rem 2.8rem 0.5rem 1rem;

          .collapse-title {
            text-transform: uppercase;
            font-size: 0.85rem;
          }

          &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 50%;
            display: block;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: #ffffff;
          }


        }

        .card-body {
          background: #f3f3f3;
          padding-top: 1rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .notes-row {
      .collapse-default {
        .card {
          .card-header {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .badge.badge-up {
    right: -13px;
  }

  .modal-content-wrapper {
    min-height: 200px;
  }
}


</style>
