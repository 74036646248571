<template>
  <div>
    <edit-cargo-info>
      <template
        #cargo-user-driver="slotProps"
      >
        <setCargoDriver :cargo="slotProps.cargo" />
      </template>
    </edit-cargo-info>
  </div>

</template>

<script>
import editCargoInfo from './components/editCargoInfo'
import setCargoDriver from './components/setCargoDriver'

export default {
  components: {
    editCargoInfo,
    setCargoDriver,
  },
}
</script>

<style lang="scss">

</style>
